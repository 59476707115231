<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
 src="https://www.hanwha-pm.com/upload/product/20210517/185621FEed.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
The DECAN series models can handle a wide range of components and maximize productivity with various PCB transfer systems.                  </h2>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>
                        Secures the applicability to components and conveyor flexibility.
                      </h5>
                    </li>
                    <li>
                      <h5> Reduces production time by optimizing the motion sequence.</h5>
                    </li>
                    <li>
                      <h5>
                       Reinforces odd-shape component recognition by applying 3D lighting system.
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />
            <h1><strong> Specifications </strong></h1>
            <br /><br />
            <div class="row container" style="font-size: 18px">
              <div class="container-fluid">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      
                      <tr>
                        <th scope="row">Number of Spindles</th>
                        <td style="font-size: 12px">6 Spindle x 2 Gantry</td>
                      </tr>
                      <tr>
                        <th scope="row">Placement Speed</th>
                        <td style="font-size: 12px">56,000 CPH (Optimum)</td>
                      </tr>
                      <tr>
                        <th scope="row">Placement Accuracy</th>
                        <td style="font-size: 12px">
                         ±40µm Cpk≥1.0 (0402 (01005 inch) chip) /±30µm Cpk≥1.0 (IC, Stage Vision)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Fly Camera</th>
                        <td style="font-size: 12px">
                         0402 ~ □21 mm
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style="width: 400px">Fix Camera</th>
                        <td style="font-size: 12px">~□42 mm (Standard) / ~□55 mm (MFOV) / ~ L75 mm Connector (MFOV)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br /><br />
                <h1><strong>Features</strong></h1>
                <br /><br />

                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_7.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Reliability</strong>
                      </h1>
                      <h3>
                        <strong>Optimized for the Placement of Odd-shape Components as well as LEDs and LED Lenses</strong>
                      </h3>
                      <p>
Minimizes defective component placement by recognizing LED direction, different models, and projection position. Places a lens based on the lighting source by recognizing the LED lighting source (Patent registration No. 14742444)                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_8.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong>
Flexible Production </strong>
                      </h1>
                      <h3>
                        <strong>
                         Machine Applicable to Large PCBs that Can be Modified on Site</strong
                        >
                      </h3>
                      <p>
Possible to modify the standard machine to the one applicable to large PCBs on site Applicable to Max. 1,200 x 460mm PCBs.                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_2.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> 

High Performance</strong>
                      </h1>
                      <h3><strong> Reduces PCB Fiducial Mark Recognition Time</strong></h3>
                      <p>
                       Optimizes the moving path for the recognition of the individual fiducial mark of the LED bar
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_6.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Productivity</strong>
                      </h1>
                      <h3>
                        <strong>Reduces the Cycle Time by Sharing Bad Marks with Other Machines</strong>
                      </h3>
                      <p>
                       Shares the information on the bad mark of the PCB recognized by the first machine with other machines in the line.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>